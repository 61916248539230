import React from 'react';
import { observer } from 'mobx-react';

import useStore from '@/hooks/useStore';

import styles from './styles.module.scss';

const PlaceHolderImage = () => {
  const { logo } = useStore('globals');
  return (
    <div className={styles.placeholder} data-testid="placeHolderImage">
      <img src={logo} alt="placeholder" />
    </div>
  );
};

export default observer(PlaceHolderImage);
