export function trackEvent(eventCategory: string, eventAction: string, eventLabel = '') {
  const gWindow: (typeof window & { dataLayer?: Array<any> }) | undefined = window;
  if (gWindow && gWindow?.dataLayer) {
    gWindow.dataLayer.push({
      event: 'GAEvent',
      eventCategory,
      eventAction,
      eventLabel,
    });
  }
}

export function trackImpression(impression: GaImpression) {
  const gWindow: (typeof window & { dataLayer?: Array<any> }) | undefined = window;
  if (gWindow && gWindow?.dataLayer) {
    gWindow.dataLayer.push({
      ecommerce: {
        impressions: [impression],
      },
    });
  }
}

export function trackProduct(product: GaProduct, action: cartOption) {
  const gWindow: (typeof window & { dataLayer?: Array<any> }) | undefined = window;
  if (gWindow && gWindow?.dataLayer) {
    let tag;
    if (action === 'addToCart') {
      tag = {
        add: {
          products: [product],
        },
      };
    } else {
      tag = {
        remove: {
          products: [product],
        },
      };
    }
    gWindow.dataLayer.push({
      event: action,
      ecommerce: {
        ...tag,
      },
    });
  }
}

export function trackCheckout(products: GaProduct[], step: number) {
  const gWindow: (typeof window & { dataLayer?: Array<any> }) | undefined = window;
  if (gWindow && gWindow?.dataLayer) {
    gWindow.dataLayer.push({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step: step },
          products: products,
        },
      },
    });
  }
}

export function trackPurchase(products: GaProduct[], paymentInfo: PaymentInformation) {
  const gWindow: (typeof window & { dataLayer?: Array<any> }) | undefined = window;
  if (gWindow && gWindow?.dataLayer) {
    gWindow.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: paymentInfo,
          products: products,
        },
      },
    });
  }
}
