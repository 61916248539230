import React from 'react';
import { useEffectOnce } from 'react-use';
import { captureException, configureScope } from '@sentry/nextjs';
import { IncomingMessage } from 'http';
import { GetServerSideProps } from 'next';

import { getServerSidePropsWithGlobalData } from '@/lib/getServerSideProps';
import { prefetchSEO } from '@/lib/seo';
import { withUrql } from '@/lib/urqlClient';

interface Props {
  statusCode: number;
  err?: any;
}

const Error = ({ statusCode, err }: Props) => {
  useEffectOnce(() => {
    if (statusCode === 404) return;

    Error.notifySentry(statusCode, err);

    if (err && process.env.NODE_ENV === 'development') {
      console.error(err);
    }
  });

  if (statusCode === 404) return <NotFoundPage />;

  return (
    <div className="errorContainer" data-testid="errorPage">
      <h1>{statusCode}</h1>
      <h2 style={{ textAlign: 'center' }}>Something Went Wrong</h2>
      <br />
    </div>
  );
};

const NotFoundPage = ({}) => {
  return (
    <div className="errorContainer" data-testid="notFoundPage">
      <h1>404</h1>
      <h2 style={{ textAlign: 'center' }}>Page Not Found</h2>
      <br />
    </div>
  );
};

Error.notifySentry = (statusCode: number | undefined, err: any, req?: IncomingMessage) => {
  if (statusCode !== 404) {
    configureScope((scope) => {
      if (req) {
        scope.setTag(`ssr`, 'true');
        scope.setExtra(`url`, req.url);
        scope.setExtra(`headers`, req.headers);
      } else {
        scope.setTag(`ssr`, 'false');
        scope.setExtra(`statusCode`, statusCode || 'unknown');
      }
    });

    captureException(err);
  }
};

export const getServerSideProps: GetServerSideProps = async (ctx) =>
  await getServerSidePropsWithGlobalData(ctx, async (context) => {
    const { res } = ctx;
    const statusCode = res ? res.statusCode : 520;
    if (statusCode === 404) {
      await prefetchSEO(context, '/404');
    }

    return { props: { statusCode } };
  });

export { Error as ErrorPage };
export default withUrql(Error);
