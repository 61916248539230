import React, { useMemo, useState } from 'react';
import cc from 'classcat';
import { useEffectOnce } from 'react-use';
import { differenceInDays } from 'date-fns';

import Image from '@/components/Image';
import PlaceHolderImage from '@/components/PlaceHolderImage';
import Placeholder from '@/components/PlaceHolderImage';
import useStore from '@/hooks/useStore';
import CommerceService from '@/lib/CommerceService';
import { trackEvent } from '@/lib/GA';
import { extractItems } from '@/lib/GraphQLHelper';
import { addressString, currencyFormat } from '@/lib/Utils';

import EndingIcon from '@/images/icons/endingsoon.svg';
import HeartFilledIcon from '@/images/icons/heart--filled.svg';
import HeartIcon from '@/images/icons/heart.svg';
import MapPinIcon from '@/images/icons/pin.svg';
import PopularIcon from '@/images/icons/popular.svg';

import styles from './styles.module.scss';

interface Props {
  offer: OfferFragment | ProductOfferFragment | ETicketFragment | GiftCardFragment;
  small?: boolean;
  wishlist?: string[];
  addToWishlist?: (id: string) => void;
}

const OfferCard = ({ offer, small, wishlist, addToWishlist }: Props) => {
  let address;
  let soldOut;
  const { now } = useStore('globals');
  const { loggedIn } = useStore('userStore');
  const { getProduct } = useStore('cart');
  const [count, setCount] = useState(0);
  const inWishlist = useMemo(() => offer.id && wishlist?.includes(offer.id), [offer.id, wishlist]);
  const handleAddToWishlist = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (addToWishlist) {
      if (
        offer.__typename === 'specialOffers_productOffers_Entry' &&
        extractItems<ETicketFragment>(offer?.product)?.[0]?.id
      ) {
        const productId = extractItems<ETicketFragment>(offer.product)?.[0]?.id;
        productId && addToWishlist(productId);
      } else if (offer.__typename === 'eTickets_Product' && offer?.id) {
        addToWishlist(offer.id);
      } else if (offer.__typename === 'giftCards_Product' && offer.id) {
        addToWishlist(offer.id);
      }
    }
    trackEvent('Wishlist', `Clicked ${inWishlist ? 'remove from wishlist' : 'add to wishlist'}`, `${offer.title}`);
  };
  useEffectOnce(() => {
    async function getTicketCount() {
      if (['eTickets_Product', 'specialOffers_productOffers_Entry'].includes(offer.__typename)) {
        let id;

        switch (offer.__typename) {
          case 'eTickets_Product':
            id = offer.id;
            break;
          case 'specialOffers_productOffers_Entry':
            const offerProduct = extractItems<ETicketFragment>(offer.product);
            id = offerProduct?.[0]?.id;
            break;
          default:
            break;
        }

        if (id) {
          const offerCount = (await CommerceService.getPurchasedNo(id)) || 0;

          if (!isNaN(offerCount)) {
            setCount(offerCount);
          }
        }
      }
    }
    getTicketCount();
  });

  switch (offer.__typename) {
    case 'specialOffers_specialOffers_Entry':
      const expiringSoon = () => {
        if (offer?.expiryDate) {
          return differenceInDays(new Date(offer.expiryDate), new Date(now)) <= 7;
        }
        return false;
      };
      address = addressString(offer?.locations);
      return (
        <a
          className={cc({ [styles.offerCard]: true, [styles.small]: small })}
          href={`/offers/${extractItems<CategoryFragment>(offer.categories)?.[0]?.slug}/${offer.slug}`}
        >
          <div className={styles.image}>
            {offer?.thumbnail?.[0]?.jpg ? <Image {...offer.thumbnail?.[0]} /> : <PlaceHolderImage />}
          </div>
          <div className={styles.body}>
            {offer.popularOffer && (
              <div className={styles.popular}>
                <PopularIcon />
              </div>
            )}
            {expiringSoon() && (
              <div className={styles.ending}>
                <EndingIcon />
              </div>
            )}
            <h3>{offer.title}</h3>
            {address && (
              <p className={styles.address}>
                <MapPinIcon />
                {address}
              </p>
            )}
            <p className={styles.description}>{offer.description}</p>
            <div className={styles.discount} dangerouslySetInnerHTML={{ __html: offer.offerDiscount || '' }} />
          </div>
        </a>
      );
    case 'specialOffers_productOffers_Entry':
      const offerProducts = extractItems<ETicketFragment>(offer?.product);
      const offerFirstProductVariant = extractItems<
        Pick<ETicketsVariant, 'id' | 'hasUnlimitedStock' | 'stock' | 'productId'>
      >(offerProducts?.[0]?.variants)?.[0];
      address = addressString(offerProducts?.[0]?.locations);
      soldOut = false;
      if (!offerFirstProductVariant?.hasUnlimitedStock && !offerFirstProductVariant?.stock) {
        soldOut = true;
      }
      return (
        <a
          className={cc({ [styles.offerCard]: true, [styles.small]: small })}
          href={`/shop/${extractItems<ProductCategoryFragment>(offerProducts?.[0]?.productCategories)?.[0]?.slug}/${
            offerProducts?.[0]?.slug
          }`}
        >
          <div className={styles.image}>
            {offerProducts?.[0]?.thumbnail?.[0] ? (
              <Image {...offerProducts?.[0]?.thumbnail?.[0]} />
            ) : (
              <PlaceHolderImage />
            )}
            {loggedIn && (
              <button
                className={styles.heart}
                onClick={handleAddToWishlist}
                data-testid={`heart-${inWishlist ? 'filled' : 'empty'}`}
              >
                {inWishlist ? <HeartFilledIcon /> : <HeartIcon />}
              </button>
            )}
          </div>
          <div className={styles.body}>
            {offer.popularOffer && (
              <div className={styles.popular}>
                <PopularIcon />
              </div>
            )}
            <h3>{offer.title}</h3>
            {address && (
              <p className={styles.address}>
                <MapPinIcon />
                {address}
              </p>
            )}
            <p className={styles.description}>{offer.description}</p>
            <div className={styles.price}>
              {soldOut ? (
                <h3 className={styles.soldOut}>Sold Out</h3>
              ) : (
                <>
                  <span>${offerProducts?.[0]?.rrp}</span>&nbsp;${offerProducts?.[0]?.defaultPrice}
                </>
              )}
            </div>
            {!!count && (
              <div className={styles.productCount}>
                <p>{count} bought</p>
              </div>
            )}
          </div>
        </a>
      );
    case 'eTickets_Product':
      soldOut = false;
      const eTicketVariants = extractItems<ETicketVariantFragment>(offer?.variants);
      const eTicketProductCategories = extractItems<ProductCategoryFragment>(offer?.productCategories);
      if (!eTicketVariants?.[0]?.hasUnlimitedStock && !eTicketVariants?.[0]?.stock) {
        soldOut = true;
      }
      return (
        <a
          className={cc({ [styles.offerCard]: true, [styles.small]: small })}
          href={`/shop/${eTicketProductCategories?.[0]?.slug}/${offer?.slug}`}
        >
          <div className={styles.image}>
            {offer?.thumbnail?.[0]?.jpg ? <Image {...offer?.thumbnail?.[0]} /> : <PlaceHolderImage />}
            {loggedIn && (
              <button
                className={styles.heart}
                onClick={handleAddToWishlist}
                data-testid={`heart-${inWishlist ? 'filled' : 'empty'}`}
              >
                {inWishlist ? <HeartFilledIcon /> : <HeartIcon />}
              </button>
            )}
          </div>
          <div className={styles.body}>
            {offer.popularOffer && (
              <div className={styles.popular}>
                <PopularIcon />
              </div>
            )}
            <h3>{offer.title}</h3>
            {address && (
              <p className={styles.address}>
                <MapPinIcon />
                {address}
              </p>
            )}
            <div className={styles.price}>
              {soldOut ? (
                <h3 className={styles.soldOut}>Sold Out</h3>
              ) : (
                <>
                  <span>{currencyFormat(offer?.rrp)}</span>&nbsp;{currencyFormat(offer?.defaultPrice)}
                </>
              )}
            </div>
            {!!count && (
              <div className={styles.productCount}>
                <p>{count} bought</p>
              </div>
            )}
          </div>
        </a>
      );
    case 'giftCards_Product':
      const giftCardVariants = extractItems<GiftCardVariantFragment>(offer?.variants);
      const product = getProduct(offer.slug!);
      const img = offer?.image?.length
        ? offer?.image[0]?.url
        : product?.bh?.productConfigurations?.[0].productImages?.[0]?.frontImage;

      if (!product) return null;
      return (
        <a className={styles.offerCard} href={`/shop/gift-cards/${product?.slug}`}>
          <div className={styles.image}>
            {img ? <img src={img} alt="" /> : <Placeholder />}
            {loggedIn && (
              <button
                className={styles.heart}
                onClick={handleAddToWishlist}
                data-testid={`heart-${inWishlist ? 'filled' : 'empty'}`}
              >
                {inWishlist ? <HeartFilledIcon /> : <HeartIcon />}
              </button>
            )}
          </div>
          <div className={styles.body}>
            <h4>{product?.title}</h4>
            <div className={styles.bottom}>
              <div className={styles.price}>
                <>
                  <span>{currencyFormat(giftCardVariants?.[0]?.fullValue)}</span>&nbsp;
                  {currencyFormat(giftCardVariants?.[0]?.price)}
                </>
                <h4>Save {product?.discount}%</h4>
              </div>
            </div>
          </div>
        </a>
      );
    default:
      return null;
  }
};

export default OfferCard;
