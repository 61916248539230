import React from 'react';

const Image = (props: Partial<AssetFragment>) => {
  if (props) {
    if (!props) return null;
    const { webp, jpg, width, height, title } = props;
    return (
      <picture>
        {webp && <source srcSet={webp} type="image/webp" />}
        <img loading="lazy" src={jpg!} alt={title!} width={width!} height={height!} className="image" />
      </picture>
    );
  }

  return null;
};

export default Image;
